.aside-form {

    .form {
        &__btn {
            .btn {
                width: 160px;
            }
        }
    }

    &__top {
        position: relative;
        padding: 18px 30px 26px;
        background-color: #569bcd;

        &-text {
            line-height: 19px;
            font-size: 13px;
            color: #FFFFFF;

            a {
                font-weight: bold;
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }

    &__body {
        background-color: #EAEAEA;
        background-color: #eaeaea;
        padding: 30px;

        .popup {
            &__row {
                &-ok {

                    &-icon {
                        width: 76px;
                        height: 76px;
                    }

                    font-size: 23px;
                    color: #569bcd;
                    line-height: 1.2;

                    span {
                        margin-top: 10px;
                        font-size: 24px;
                        color: #569BCD;  
                    }
                }
            }
        }
    }



    &__title {
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
    }
}

.aside-right {
    .form {
        &__check {
            &-control {
                &::before {
                    background-image: url(../images/common/checkred.svg) !important;
                    top: 2px;
                    left: 0px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .aside-right {
        margin-top: 75px;
    }


    .aside-form {
        display: flex;

        &__title {
            font-size: 30px;
            line-height: 1.2;
            margin-bottom: 17px;
        }

        &__top {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 370px;
            flex: none;

            &-text {
                font-size: 16px;
            }
        }

        &__body {
            flex-grow: 1;
            padding: 41px;
        }
    }
}

@media (max-width: 992px) {
    .aside-form {
        display: flex;

        
        &__top {
            width: 273px;

            &-text {
                font-size: 13px;
                line-height: 19px;
            }
        }

        &__title {
            font-size: 20px;
        }
    }
}

@media (max-width: 768px) {
    .aside-form {
        &__top {
            width: 215px;
            padding: 20px;
        }

        &__body {
            padding: 23px;
        }
    }

    .aside-right {
        margin-top: 40px;
    }
}

@media (max-width: 560px) {
    .aside-right {
        margin-top: 40px;
    }

    .aside-form {
        display: block;

        &__top {
            width: 100%;
            padding: 20px 30px;
        }

        &__body {
            padding: 30px;
        }
 
        &__title {
            margin-bottom: 5px;
        }
    }
}