.main-news {
    margin-top: 55px;
    
    &__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        > div {
            width: calc(~"33.3% - 30px");
            margin: 0 15px;
        }

    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        h2 {
            margin-bottom: 0;
        }

        .btn {
            margin-left: 46px;
            padding: 8px 24px 9px;
        }
    }

    &__text {
        font-family: "Roboto" , sans-serif;
        line-height: 18px;
        font-size: 14px;
        color: #2D2B2B;
        transition: color 0.3s ease;
    }

    &__img {

        &-block {
            position: relative;
            height: 240px;
            margin-bottom: 14px;
            overflow: hidden;
        }
      
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        transition: transform 0.3s ease;
    }

    &__more {
        use {
            fill: #569BCD;
            transition: fill 0.3s ease;
        }
    }

    &__date {
        color: #fff;
        line-height: 17px;
        font-family: "Roboto" , sans-serif;
        font-size: 13px;
        text-align: center;
        background-color: #569bcd;
        display: inline-block;
        padding: 3px 10px;
        position: absolute;
        left: 0;
        bottom: 0;
    }



    &__item {
        
        text-decoration: none;
       
        margin-bottom: 30px;

        &:hover {
            .main-news {
                &__more {
                    use {
                        fill: #D22329;
                    }
                }

                &__img {
                   transform: scale(1.05);
                }

                &__text {
                    color: #D22329; 
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .main-news {
        &__img {
            &-block {
                height: 195px;
            }
        }
    }
}

@media (max-width: 560px) {
    .main-news {

        &__block {
            > div {
                width: calc(~"50% - 30px");
                margin-bottom: 10px;
            }
        }
        /*> div {
            width: calc(~"50% - 30px");
            margin: 0 15px;
        }*/
    }
}

@media (max-width: 480px) {
    .main-news {
        &__block {
            > div {
                width: calc(~"100% - 30px");

            }
        }

        &__img {
            &-block {
                margin-bottom: 10px;
            }
        }

        &__title {
            margin-bottom: 24px;

            a {
                display: none;
            }
        }
    }
}