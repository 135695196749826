.gallery {
    margin-bottom: 24px;
    &__block {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
    }

    &__item {
        width: 25%;
        padding-left: 15px;

        img {
            max-width: 100%;
        }
    }
}