.filter {
    &__block {
        padding: 20px;
        background-color: #f6f6f6;
        margin-bottom: 30px;
        position: relative;
        z-index: 18;
    }
    
    &__title {
        font-weight: 600;
        font-size: 14px;
        color: #2d2b2b;
        margin-bottom: 17px;
    }

    &__count {
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        font-family: Roboto,sans-serif;
        color: #fff;
        background-color: #75bbee;
        display: flex;
        right: 277px;
        position: fixed;
        padding: 8px 18px;
        top: 50%;
        transform: translateY(calc(~"-50% + 2px"));
        transition: background-color 0.3s ease; 

        &:hover {
            background-color: #569BCD;

            &::before {
                border-left: 8px solid #569BCD;
            }
        }

        &-wrap {
            display: flex;

            a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
            }

            #modef_num {
                margin-left: 5px;
            }
        }

        &.desc {
            right: calc(~"100% + 10px");
            position: absolute;
        }

        a {
            font-size: 0;
        }

        &::before {
            content: " ";
            position: absolute;
            border: 8px solid transparent;
            border-left: 8px solid #75bbee;
            right: -16px;
            top: 8px;
            transition: border-color 0.3s ease;
        }
    }


    &__btn {
        .btn {
            padding: 9px 15px;
            font-size: 11px;
            text-decoration: none;
            width: 100%;
            margin-bottom: 8px;
            box-shadow: none;
        }
    }

    &__body {
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 9px;
        margin-bottom: 20px;
    }

    &-btn-main {
        width: 196px;
        margin-left: 15px; 
        display: none;
        flex: none;
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        background-color: #75BBEE;
    }

    &__count {
        display: none;
    }
}

@media (max-width:1200px) {
    .filter {
        width: 264px;
        background: #F6F6F6;
        padding: 20px;
        min-height: 100%;
        position: relative;

        &-btn-main {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 5px;
            }
        }

        &__count {
            line-height: 20px;
            font-size: 14px;
            text-align: center;
            font-family: Roboto,sans-serif;
            color: #fff;
            background: #75bbee;
            display: flex;
            right: 277px;
            position: fixed;
            padding: 8px 18px;
            top: 50%;
            transform: translateY(calc(~"-50% + 2px"));

            &::before {
                content: " ";
                position: absolute;
                border: 8px solid transparent;
                border-left: 8px solid #75bbee;
                right: -16px;
                top: 8px;
            }
        }

        &__block {
            position: fixed;
            top: 65px;
            z-index: 12;
            right: 0;
            bottom: 0;
            padding: 0;
            margin-bottom: 0;
            overflow-y: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            background-color: rgba(8, 24, 65, 0.6);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            form {
                height: 100%;
            }

            &.is-open {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}


@media (max-width:560px) {
    .filter {
        &-btn-main {
            width: 40px;
            span {
                display: none;
            }
        }
    }
}