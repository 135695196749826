* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    color: #2D2B2B;
    overflow-x: hidden;

    &.is-hidden {
        overflow: hidden;
    }
}

a, button {
    outline: 0;
}

p {
    font-family: Roboto,sans-serif;
    line-height: 21px;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 15px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;

    &__content {
        padding-top: 23px;
        padding-bottom: 36px;

        ul {
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            
            li {
                position: relative;
                list-style: none;
                line-height: 20px;
                font-size: 14px;
                font-family: Roboto,serif;
                color: #2d2b2b;
                padding-left: 20px;
                margin-bottom: 5px;

                &::before {
                    content: " ";
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #569bcd;
                    position: absolute;
                    top: 7px;
                    left: 0;
                }
            }
        }

        ol {
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            counter-reset: myCounter;

            li {
                position: relative;
                list-style: none;
                line-height: 20px;
                font-size: 14px;
                font-family: Roboto,serif;
                color: #2d2b2b;
                padding-left: 20px;
                margin-bottom: 5px;

                &::before {
                    counter-increment: myCounter;
                    content: counter(myCounter);
                    font-weight: 700;
                    line-height: 20px;
                    font-size: 14px;
                    color: #569bcd;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

            }
        }

        &--about {
            img {
                float: left;
                max-width: 264px;
                margin: 0 30px 10px 0px;
                margin-top: 7px;
            }
        }

        &--index {
            position: relative;
            padding-bottom: 90px;

           
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

h1 {
    font-weight: 700;
    line-height: 46px;
    font-size: 34px;
    text-transform: uppercase;
    margin: 0;
    font-family: Montserrat,sans-serif;
}

h2 {
    font-weight: bold;
    line-height: 40px;
    font-size: 26px;
    color: #2d2b2b;
    margin-bottom: 20px;
    margin-top: 0;
    font-family: Montserrat,sans-serif;
}

h3 {
    font-weight: bold;
    line-height: 25px;
    font-size: 18px;
    color: #2d2b2b;
    margin-bottom: 13px;
    margin-top: 0;
    font-family: Montserrat,sans-serif;
}

.text-column {
   /* columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;*/
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    color: #000000;

    table {
        table-layout: fixed;
        border-collapse: collapse;

        td {
            padding: 3px;
            text-align: left;
        }
    }
}

img {
    vertical-align: bottom;
}

.heading {
    margin-top: 23px;

    &--contact {
        text-align: center;
        margin-top: 52px;
    }
}

.hidden-desctop {
    display: none !important;
}

.main-news {
    position: relative;
    &::before {
        content: " ";
        width: 100%;
        height: 50%;
        position: absolute;
        background-color: #f8f8f8;
        bottom: 0;
    }
}

.main-about {
    position: relative;
    margin-top: 23px;
    padding-top: 32px;

    &::before {
        content: " ";
        width: 100%;
        /* height: 100%; */
        position: absolute;
        background-color: #f8f8f8;
        bottom: -90px;
        top: -24px;
    }
    
    p {
        max-width: 85%;
    }
}



.main-quote {
    background-color: #F8F8F8;
}

@media (max-width: 1200px) {
    .hidden-desctop {
        display: block !important;
    }

    .text-column {
        overflow-x: auto;
        table {
            table-layout: fixed;
            width: 992px;
        }
    }

    
    .hidden-mobile {
        display: none !important;
    }
}


@media (max-width: 992px) {
    h1 {
        line-height: 46px;
        font-size: 34px;
    }

    h2 {
        line-height: 37px;
        font-size: 24px;
    }

    .heading {
        &--contant {
            margin-top: 40px;
        }
    }

    .logo {
        width: 40px;
        height: 40px;

        img {
            width: 100%;
            height: auto;
        }
    }
    


    .wrapper {
        &__content {
            padding-top: 15px;
            &--index {
                padding-bottom: 40px;

                &::before {
                    display: none;
                }
            }
        }
    }

    .main-about {
        &::before {
            bottom: -40px;
        }
    }
}

@media (max-width: 768px) {
    h1 {
        line-height: 40px;
        font-size: 30px;
    }

    h2 {
        line-height: 29px;
        font-size: 22px;        
    }

    .text-column {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }

    .main-news {
        margin-top: 20px;

        &::before {
            display: none;
        }
    }

    .main-about {
        margin-top: 45px;
        padding-top: 0;

        p {
            max-width: 100%;
        }
    }

}

@media (max-width: 560px) {
    h1 {
        line-height: 34px;
        font-size: 25px;
    }

    .wrapper {
        &__content {
            padding-top: 10px;
            padding-bottom: 15px;
        }
    }
}


@media (max-width: 360px) {
    h1 {
        line-height: 29px;
        font-size: 22px;
    }

    h2 {
        line-height: 25px;
        font-size: 19px;      
    }
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .modal-footer:after {
    clear: both;
}



.video-block {
    max-width: 558px;
    height: auto;
    margin-bottom: 30px;
    width: auto;

    video {
        height: auto;
        width: 100%;
    }
}



.share {
    margin-top: 30px;

    &__title {
        font-size: 13px;
        color: #9e9e9e;
        font-weight: 300;
        margin-bottom: 5px;
    }

    li {
        padding-left: 0 !important;  

        &::before {
            display: none;
        }
    }
}

