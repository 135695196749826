.checkbox {
    display: inline-flex;
    position: relative;

    &__row {
        position: relative;
        display: flex;
        margin-bottom: 9px; 

        input[type="checkbox"] {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            &:checked {
                &+.checkbox {
                    &__control {
                        background-color: #75BBEE;
                        border: 1px solid #75BBEE;

                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
    
        }
    }



    &__control {
        display: flex;
        position: relative;
        flex: none;
        width: 18px;
        height: 18px;
        border: 1px solid #D6D6D6;
        transition: background-color 0.3s, border-color 0.3s ease;
        margin-right: 8px;

        &::before {
            content: " ";
            background-image: url("../images/common/checkw.svg");
            display: block;
            position: absolute;
            opacity: 0;
            transition: opacity .3s ease;
            width: 14px;
            height: 10px;
            top: 3px;
            left: 1px;
        }
    }

    &__text {
        line-height: 17px;
        font-size: 13px;
        font-family: Roboto,sans-serif;
        color: #6C6C6C;
    }
}