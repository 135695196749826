.social {
    text-align: right;
    padding-top: 12px;

    &__title {
        font-family: Roboto,sans-serif;
        color: #d6d6d6;
        font-size: 14px;
        padding-right: 25px;
        margin-bottom: 11px;
    }

    &__block {
        display: flex;
        justify-content: flex-end;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13px;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        border: 1px solid #878787;
        transition: border-color 0.3s ease;

        use {
            fill: #878787;
            transition: fill 0.3s ease;
            
        }

        &:last-child {
            margin-right: 0;
        }


        &:hover {
            border: 1px solid #fff;

            use {
                fill: #fff;
            }
        }
    }
}


@media (max-width: 992px) {
    .social {
        padding-top: 0;
    }
}

@media (max-width: 768px) {
    .social {
        text-align: center;
        margin-bottom: 20px;

        &__title {
            padding: 0;
            margin-bottom: 5px;
        }

        &__block {
            justify-content: center;
        }
    }
}