
.mobile-catalog {
    display: none;
}


@media (max-width: 992px) {
    .mobile-catalog {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        width: 100%;
        z-index: 40;
        right: 0px;
        top: 65px;
        transform: translateY(calc(~"-100% - 250px"));
        transition: transform 0.3s ease;
        background-color: #f2f2f2;

        &::before {
            content: " ";
            width: 100%;
            height: 116px;
            display: block;
            position: absolute;
            background: linear-gradient(180deg,rgba(0,0,0,.63),rgba(0,0,0,.19) 35.36%,transparent);
            opacity: .5;
            bottom: -116px;
        }

        &.is-open {
            transform: translateY(0);
        }

        &__icon {
            flex: none;
            margin-right: 15px;
            width: 45px;

            img {
                max-width: 100%;    
                height: auto;
            }
        }

        &__text {
            font-weight: 500;
            font-size: 15px;
            color: #2D2B2B;
            transition: color 0.3s ease;
        }

        &__item {
            display: flex;
            align-items: center;
            width: 33.3%;
            margin-bottom: 0;
            padding: 0 15px;
            text-decoration: none;
            padding-right: 30px;
            min-height: 70px;
            background-color: #f2f2f2;
            border-left: 1px solid #fff;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-top: 1px solid #fff;

            &:hover {
                .main-catalog {
                    &__text {
                        color: #D22329;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .mobile-catalog {
        &__item {
            width: 50%;
        }

        &__text {
            font-size: 12px;
        }
    }
}

@media (max-width:   470px) {
    .mobile-catalog {
        &__item {
            width: 100%;
            min-height: 55px;
        }

        &__icon {
            height: 100%;
            display: flex;
            align-items: center;

            img {
                max-height: 93%;
                width: auto;
            }
        }
    }
}

