.subscription {

    position: relative;

    &__block {
        padding: 10px 25px 0px;
    }

    &__title {
        line-height: 19px;
        font-size: 14px;
        color: #fff;
        font-family: Roboto,sans-serif;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .svg-icon {
            margin-right: 8px;
        }
    }

    &__wrap {
        .form {
            &__check {
                margin-top: 10px;

                &-text {
                    color: #fff;
                }

                a {
                    color: #fff;
                }

                &-control {
                    background-color: transparent;
                    border: 1px solid #fff;
                }
            }
        }
    }


    input[type="text"] {
        background: #fff;
        border-radius: 20px;
        width: 100%;
        height: 41px;
        border: 0;
        padding: 0 290px 0 18px;
    }

    .btn {
        position: absolute;
        height: 41px;
        top: 0;
        right: -2px;
    }

    label {
        position: absolute;
        font-size: 16px;
        color: #6c6c6c;
        font-family: Roboto,sans-serif;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 18px;
    }
}

@media (max-width: 1200px) {
    .subscription {
        input[type="text"] { 
            padding: 0 178px 0 18px;
        }
    }
}

@media (max-width: 992px) {
    .subscription {
        &__block {
            padding: 0 10px;
            margin-bottom: 20px;
        }
    }   

}

@media (max-width: 992px) {
    .subscription {
        &__block {
            padding: 0;
            margin-bottom: 30px;
        }

        &__title {
            span {
                display: none;
            }
        }
    }
}

@media (max-width: 768px) {
    .subscription {
        &__block {
            padding: 0 10px;
        }

        &__title {
            justify-content: center;
            margin-bottom: 15px;
        }
    }
}