.associated {
    padding-top: 65px;
    padding-bottom: 9px;

    h2 {
        text-align: center;
    }

    &__slider {
        &-item {
            padding: 25px 15px;
        }

        .slick-arrow {
            background-color: transparent;
            position: absolute;
            border: 0;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            outline: 0;
        }

        .slick-next {
            right: -25px;
        }

        .slick-prev {
            left: -25px;
        }
    }


    .catalog-level {
        &__item {
            width: 100%;
            display: block;
            margin: 0;
        }
    }
}

@media (max-width: 1200px) {
    .associated {
        padding-top: 50px;
    }
}


@media (max-width: 768px) {
    .associated {
        &__slider {
            padding: 0 20px;

            .slick-next {
                right: -5px;
            }

            .slick-prev {
                left: -5px;
            }
             
        }

    }
}