.catalog-level {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    position: relative;
    z-index: 10;

    &__img {
        width: 222px;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
        margin-top: 20px;
    }

    &__3d {
        position: absolute;
        right: 15px;
        top: 16px;
        z-index: 4;
    }
    
    &__new {
        position: absolute;
        left: -7px;
        top: 17px;
    }

    &__name {
        color: #000;
        text-align: center;
        text-decoration: none;
        line-height: 21px;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        max-width: 90%;
        margin: 0 auto;
        height: 65px;
        margin-bottom: 5px;
    }

    &__more {
        display: flex;
        justify-content: center;

        use {
            fill: #E0E0E0;
            transition: fill 0.3s ease;
        }
    }

    &__item {
        position: relative;
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(0,0,0,.08);
        transition: box-shadow .3s ease;
        width: calc(~"33.3% - 30px");
        padding: 15px 15px 15px 25px;
        margin: 0 15px 30px 15px;
        text-decoration: none;
        height: 345px;

        &--3d {
            .catalog-level {

                &__img {
                    transition: transform 0.3s ease;
                }
            }

            &:hover {
                .catalog-level {
                    &__img {
                        transform: scale(-1, 1);
                    }
                }
            }
        }

        &:hover {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

            .catalog-level {
                &__more {
                    use {
                        fill: #D22329;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .catalog-level { 

        &__item {
            height: 290px;
        }

        &__img {
            width: 100%;
            height: 180px;
            margin-top: 0;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        &__name {
            max-width: 100%;
        }

        &__3d {
            right: 10px;
            top: 7px;
            width: 30px;

            img {
                max-width: 100%;
            }
        }

        &__new {
            top: 5px;
        }
    }
}

@media (max-width: 700px) {
    .catalog-level { 

        &__img {
            width: auto;
            max-width: 189px;
            margin: 0 auto;
            margin-bottom: 3px;
        }

        &__item {
            width: calc(~"50% - 30px");
            padding: 15px;
        }

    }
}

@media (max-width: 360px) {
    .catalog-level { 
        &__item {
            width: calc(~"100% - 30px");
        }
    }
}