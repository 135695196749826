.pagination {
    display: flex;

    &__block {
        justify-content: space-between;
        display: flex;
    }

    &__to {
        width: 36px;
        height: 36px;
        justify-content: center;
        display: inline-flex;
        align-items: center;
    }

    &__prev {
        color: #569bcd;
        line-height: 20px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        text-decoration: none;
        display: flex;
        align-items: center;

        .svg-icon {
            margin-right: 10px;
            transform: rotate(180deg);
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    &__more {
        color: #569bcd;
        line-height: 20px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        text-decoration: none;
        display: flex;
        align-items: center;

        .svg-icon {
            margin-left: 10px;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        font-size: 15px;
        color: #383B41;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s ease;

        &:hover {
            background-color: #75BBEE;
            color: #fff;
        }

        &.is-active {
            background-color: #75BBEE;
            color: #fff; 
        }
    }
}