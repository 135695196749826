.search {
    height: 111px;
    width: 100%;
    background-color: rgba(8,24,65,.9);
    position: absolute;
    top: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(calc(-100% - 70px));
    transition: transform .3s ease;

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        transition: opacity 0.3s ease;

        use {
            fill: #fff;
        }

        &:hover {
            opacity: 0.8;
        }
    }
    
    &.is-open {
        transform: translateY(0px);
    }

    &__block {
        width: 557px;
        position: relative;


        input[type="text"] {
            height: 50px;
            width: 100%;
            background-color: transparent;
            border: 0;
            color: rgba(250, 250, 250 ,0.5);
            outline: 0;
            font-weight: 300;
            font-size: 24px;
            border-bottom: 1px solid rgba(250, 250, 250 ,0.5);

            &::-webkit-input-placeholder {font-family: 'Montserrat', sans-serif;  color: rgba(250, 250, 250 ,0.5);  font-weight: 300; }
            &::-moz-placeholder         {font-family: 'Montserrat', sans-serif;  color: rgba(250, 250, 250 ,0.5);  font-weight: 300; }
            &:-moz-placeholder           {font-family: 'Montserrat', sans-serif;  color: rgba(250, 250, 250 ,0.5);  font-weight: 300; }
            &:-ms-input-placeholder      {font-family: 'Montserrat', sans-serif;  color: rgba(250, 250, 250 ,0.5);  font-weight: 300; }
        }
    }

    &__submit {
        position: absolute;
        right: 0;
        top: 0;
        background-color: transparent;
        border: 0;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &__open {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #FFFFFF; 
        transition: box-shadow 0.3s ease;
        margin-left: 15px;


        .svg-icon {
            use {
                fill: #7E004A;
            }
        }

        &:hover {
            box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.3);
        }
    }
    
}

@media (max-width: 768px){
    .search {
        height: 80px;
        padding: 0 30px;
        padding-bottom: 10px;
        transform: translateY(calc(~"100% - 270px"));

        &__block {
            width: 100%;

            input[type=text] {
                height: 40px;
            }
        }

        &__close {
            right: 10px;
            top: 10px;
        }

        &__submit {
            height: 40px;
            width: 40px;
        }
    }
}