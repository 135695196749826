.navigation {
    display: flex;
    height: 100%;

    &__block {
        display: flex;
        height: 100%;
        padding-left: 78px;
    
        .btn {
            display: none;
        }
    }

    &__close {
        display: none;
    }

    ul {
        display: flex;
        padding: 0;
        margin: 0;

        li {
            display: inline-flex;
            margin-right: 32px;

            a {
                display: flex;
                flex-grow: 1;
                font-weight: 600;
                line-height: normal;
                font-size: 13px;
                text-transform: uppercase;
                color: #fff;
                align-items: center;
                text-decoration: none;
                transition: color 0.3s , border-color 0.3s ease;
                border-top: 3px solid transparent;

                &:hover {
                    color: rgba(250, 250, 250, 0.6);
                   
                }

                &.is-active {
                    color: #fff;
                    border-top: 3px solid #fff;
                }
            }
        }
    }
}

@media (max-width: 992px) {

    .header__main {
        height: 65px;
    }

    .navigation {
        width: 100%;
        padding: 0;
        flex-grow: 1;
        margin-bottom: 20px;
        flex: none;
        height: auto;

        ul {
            li {
                margin-right: 0;
                height: 75px;
                flex: none;
            }
        }

        
        &__close {
            display: block;
            position: absolute;
            top: 20px;
            right: 20px;
        }


        &__block {
            position: fixed;
            background-color: rgba(8,24,65,.95);
            z-index: 105;
            width: 100vw;
            min-height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            flex-direction: column;
            padding: 60px 25px;
            overflow-y: auto;        
            justify-content: space-between;
            transform: translateY(-100%);
            transition: transform 0.3s ease;

            &.is-open {
                transform: translateY(0);

            }

                
            .btn {
                display: inline-flex;
                width: auto;
                align-self: center;
                flex: none;
            }
        }
    

        ul {
            flex-direction: column;
            flex-grow: 1;

            li {
                a {
                    height: 75px;
                    display: flex;
                    justify-content: center;
                    border-top: 1px solid rgba(196, 196, 196, 0.3) !important;
                }

                &:last-child {
                    border-bottom: 1px solid rgba(196, 196, 196, 0.3) !important;
                }
            }
        }
    }
}