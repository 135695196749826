.diler {

    &__item {
        margin-bottom: 2px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: #F6F6F6;
        padding-right: 18px;
        cursor: pointer;
        transition: background-color 0.3s ease;   
        
        &::before {
            content: " ";
            width: 43px;
            height: 43px;
            background-color: #d6d6d6;
            transition: background-color 0.3s ease;   
            position: absolute;
            left: 0;
            top: 0;
        }

        &.is-active {
            background-color: #FFFFFF;

            &::before {
                background-color: #FFFFFF;  
            }

            .diler {
                &__top {

                    &-icon {
                        transform: rotate(180deg);

                        .svg-icon {
                            use {
                                fill:#D6D6D6;
                            }
                        }
                    }
                }
            }
        }

        &-info {
            display: flex;
            align-items: center;
        }

        &-count {
            font-size: 14px;
            text-align: right;
            font-family: Roboto,sans-serif;
            color: #6c6c6c;
            width: 90px;
            flex: none;
        }

        &-name {
            font-weight: 600;
            line-height: normal;
            font-size: 18px;
            color: #d22329;
            padding-left: 14px;

            span {
                border-bottom: 2px dotted rgba(210,35,41,.5);
                line-height: 1.1;
                display: inline-flex;
            }
        }

        &-icon {
            width: 43px;
            height: 43px;
            display: flex;
            align-items: flex-start;
            position: relative;
            z-index: 10;
            padding-top: 9px;
            transition: transform 0.3s ease;
            justify-content: center;   

            .svg-icon {
                use {
                    fill: #fff;
                    transition: fill 0.3s ease;
                }
            }
        }
    }

    &__body {
        display: none;
        padding-left: 55px;
        padding-top: 18px;
    }

    &-firm {
        margin-bottom: 30px;

        &__top {
            display: flex;
            align-items: center;
            margin-bottom: 11px;
        }

        &__icon {
            margin-right: 8px;
        }

        &__name {
            font-weight: bold;
            line-height: 25px;
            font-size: 18px;
        }

        dl {
            display: flex;
            margin: 0;
            margin-bottom: 5px;

            dt {
                font-family: "Roboto", sans-serif;
                color: #6C6C6C;
                line-height: 20px;
                font-size: 14px;
                width: 76px;
                flex:none;
            }

            dd {
                font-family: "Roboto", sans-serif;
                line-height: 20px;
                font-size: 14px;
                color: #2D2B2B;
                margin: 0;

                a {
                    font-family: "Roboto", sans-serif;
                    line-height: 20px;
                    font-size: 14px;
                    color: #2D2B2B;
                    text-decoration: none;

                    &.cite {
                        color: #569BCD;
                        text-decoration: underline;
                    }
                }

            }
        }
    }
}

@media (max-width: 560px) {
    .diler {
        &__top {
            padding-right: 7px;

            &-name {
                font-size: 15px;
                padding-left: 10px;
            }
        }
    }
}