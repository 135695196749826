.download {
    margin-bottom: 24px;
    &__item {
        display: flex;
        margin-bottom: 17px;
        align-items: center;  
    
    }

    &__icon {
        margin-right: 11px;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }
    

    &__top {
        display: flex;
    }

    &__icon {
        img {
            margin: 0;
        }
    }

    &__name {
        line-height: 20px;
        font-size: 15px;
        font-family: Roboto,serif;
        color: #2a2d3b !important;
        transition: color 0.3s ease;

        &:hover {
            color: #d22329 !important;
        }
    }

    &__size {
        font-size: 15px;
        line-height: 20px;
        margin-left: 5px;
        font-family: Roboto,serif;
        color: #D6D6D6;
    }

    &__link {
        color: #d22329;
        text-decoration: underline;
        font-size: 12px;
        line-height: 11px;
    }
}