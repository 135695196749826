.footer {
    background-color: #2d2b2b;
    padding: 30px 0;
}

.copyright {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #D6D6D6;
    margin-top: 22px;
}

.polit {
    line-height: 20px;
    font-size: 13px;
    text-decoration-line: underline;
    font-family: 'Roboto', sans-serif;
    color: #D6D6D6;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }
}

.youx {

    margin-top: 18px;
    display: inline-flex;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.8;
    }
}

@media (max-width: 992px) {
    .copyright {
        display: inline-flex;
        margin-right: 10px;
    }

    .polit {
        display: inline-flex;
        margin-right: 10px;
    }

    .youx {
        position: absolute;
        right: 0;
        bottom: -3px;
    }
}

@media (max-width: 768px) {
    .youx {
        position: relative;
        right: auto;
        bottom: auto;
    }

    .polit {
        margin-right: 0;
    }

    .copyright {
        margin-bottom: 10px;
        margin-top: 0;
    }

    .footer {
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}