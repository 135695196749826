.catalog-section {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 25%;
        padding: 0 15px;
        position: relative;
        margin-bottom: 30px;

        &:hover {
            .catalog-section {
                &__top {
                    -webkit-transform: rotateX(90deg) translateY(-22px);
                    -moz-transform: rotateX(90deg) translateY(-22px);
                    transform: rotateX(90deg) translateY(-22px);
                    -ms-transform: none!important;
                }
            }
        }
    }

    &__img {
        margin-right: 17px;
        flex: none;
    }


    &__body {
        padding-top: 13px;
    }

    &__link {
        display: flex;
        line-height: 20px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        color: #2d2b2b;
        text-decoration: none;
        padding-left: 18px;
        margin-bottom: 10px;
        position: relative;
        transition: color 0.3s ease;

        &::before {
            content: " ";
            width: 8px;
            height: 9px;
            display: block;
            position: absolute;
            top: 4px;
            background-image: url(../images/common/mark.svg);
            left: 0;
        }

        &:hover {
            color: #D22329;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 25;
        border-bottom: 2px solid #d22329;
        background-color: #f6f6f6;
        font-weight: 500;
        text-decoration: none;
        line-height: normal;
        font-size: 15px;
        color: #2d2b2b;
        height: 94px;
        padding: 0 15px;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
        -webkit-transform-origin: 50% 0;
        -moz-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;

        &-hidden {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background-color: #D22329;
            color: #fff;
            padding: 0 15px;
            content: attr(data-hover);
            -webkit-transition: background 0.3s;
            -moz-transition: background 0.3s;
            transition: background 0.3s;
            -webkit-transform: rotateX(-90deg);
            -moz-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
            -webkit-transform-origin: 50% 0;
            -moz-transform-origin: 50% 0;
            transform-origin: 50% 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;
        }

        &::before {
           /* position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            line-height: 36px;
            background: #000;
            content: attr(data-hover);
            -webkit-transition: background 0.3s;
            -moz-transition: background 0.3s;
            transition: background 0.3s;
            -webkit-transform: rotateX(-90deg);
            -moz-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
            -webkit-transform-origin: 50% 0;
            -moz-transform-origin: 50% 0;
            transform-origin: 50% 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            box-sizing: border-box;*/
        }

        img {
            vertical-align: bottom;
        }
    }
}

@media (max-width: 1200px) {
    .catalog-section {

        &__img {
            width: 45px;
            height: 81%;
            display: flex;
            align-items: center;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    
        &__item {
            width: 33.3%;
        }

        &__top {
            height: 70px;
            font-size: 12px;
        }
    }
}

@media (max-width: 768px) {
    .catalog-section {
        &__item {
            width: 50%;
        }
    }
}

@media (max-width: 560px) {
    .catalog-section {
        &__item {
            width: 100%;
        }
    }
}