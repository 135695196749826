.advantages {

    &__block {
        display: flex;
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        color: #d22329;
        margin-bottom: 4px;
    }

    &__col {

        width: 50%;

        &:first-child {
            padding-right: 15px;
        } 
        
        &:last-child {
            padding-left: 15px;
        }
        
    }

    &__body {
        padding-left: 10px;
    }

    &__item {
        display: flex;
        margin-bottom: 20px;
    }

    &__text {
        font-family: "Roboto", sans-serif;
        line-height: 20px;
        font-size: 14px;
        color: #2D2B2B;
    }
}   

@media (max-width: 560px) {
    .advantages {

        &__block {
            flex-wrap: wrap;
        }

        &__col {
            width: 100%;

            &:last-child {
                padding-left: 0;
            }
        }
    }
}