.main-catalog {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;

    &__icon {
        flex: none;
        margin-right: 15px;
        width: 58px;
    }

    &__text {
        font-weight: 500;
        font-size: 15px;
        color: #2D2B2B;
        transition: color 0.3s ease;
    }

    &__item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: 15px;
        padding: 0 15px;
        text-decoration: none;
        padding-right: 30px;
        min-height: 92px;

        &:hover {
            .main-catalog {
                &__text {
                    color: #D22329;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .main-catalog {
        &__item {
            width: 33.333%;;
        }
    }
}

@media (max-width: 768px) {
    .main-catalog {
        &__item {
            width: 50%;
        }
    }
}

@media (max-width: 520px) {
    .main-catalog {
        margin-top: 15px;
        &__item {
            width: 100%;
            height: auto;
            min-height: inherit;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}