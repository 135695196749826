.contact {
    display: flex;
    justify-content: space-between;
    margin-top: 39px;

    &__img {
        margin-bottom: 7px;
        height: 35px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(~"33.3% - 30px");
        margin: 0 15px;
            
        text-align: center;

        p {
            line-height: 25px;
            font-size: 16px;
            font-family: Montserrat,sans-serif;
        }

        a {
            color: #f42027;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__name {
        color: #2D2B2B;
        line-height: 25px;
        font-size: 16px;
        text-decoration: none;
    }
}

.map {
    height: 358px;
    margin-top: 31px;
}

.contact-form {
    background-image: url(../images/common/bg.png);
    background-size: cover;
    background-position: 50%;
    padding: 62px 0 45px;
    margin-bottom: -36px;
    min-height: 518px;

    &__ok {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &-icon {
            margin-bottom: 10px;
        }

        span {
            font-size: 24px;
            color: #fff;
            text-align: center;

        }
    }

    &--ok {
        height: 518px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    h2 {
        color: #fff;
        text-align: center;
        margin-bottom: 41px;
    }

    .form {

        &__btn {
            width: 167px;
            margin: 0 auto;
        }

        &__field {
            textarea {
                height: 175px;
            }
        }

        &__check {
            margin: 0 auto;
            width: 310px;
            margin-bottom: 30px;
            margin-top: 11px;

            &-control {
                background-color: transparent;
                border: 1px solid #FFFFFF;
            }

            &-text {
                color: #fff;
            }
            a {
                color: #fff;
            }
        }
    }
}

@media (max-width: 560px) {
    .contact {
        flex-wrap: wrap;

        &__item {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}