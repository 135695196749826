.quote {
    margin-top: 27px;
    position: relative;

    &::before {
        content: " ";
        width: 80px;
        height: 77px;
        background-image: url(../images/common/quote.svg);
        position: absolute;
        top: -17px;
        left: -60px;
    }

    &__text {
        font-style: italic;
        font-weight: 600;
        line-height: 22px;
        font-size: 15px;
        color: #2d2b2b;
        margin-bottom: 16px;
        position: relative;
        z-index: 10;
        font-family: 'Montserrat Alternates', sans-serif;
    }

    &__avatar {
        width: 83px;
        height: 83px; 
        border-radius: 80px;
        flex: none;

        img {
            max-width: 100%;
        }
    }


    &__man {
        display: flex;
        align-items: center;

        &-body {
            padding-left: 16px;
        }

        &-name {
            font-weight: bold;
            line-height: 19px;
            font-size: 14px;
            color: #6C6C6C; 
        }

        &-post {
            line-height: 19px;
            font-size: 14px;
            color: #6C6C6C; 
        }
    }
}

@media (max-width: 992px) {
    .quote {
        display: flex;
        margin-left: 61px;
        margin-top: 64px;

        &__text {
            padding-right: 20px;
        }

        &__avatar {
            margin-bottom: 5px;
        }

        &__man {
            width: 33.3%;
            flex: none;
            flex-direction: column;
            align-items: flex-start;
            margin-top: -17px;

            &-body {
                padding-left: 0;
            }
        }
    }
}

@media (max-width: 560px) {
    .quote {
        flex-direction: column;

        &__man {
            width: 100%;
            flex-direction: row;
            align-items: center;
            margin-top: 0;

            &-body {
                padding-left: 15px;
            }
        }
    }
}