.btn {
    display: inline-flex;
    border-radius: 20px;
    border: 1px solid hsla(0,0%,100%,.7);
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    padding: 12px 28px;
    line-height: 1;
    text-align: center;
    transition: color .3s,border-color .3s,box-shadow .3s,background-color .3s ease;
    align-items: center;
    outline: 0;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    font-family: Montserrat,sans-serif;

    &:hover {
        color: #D22329;
        background-color: #fff;
        box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.3);
    }

    &--small {
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.1em;
    }

    &--outline-small {
        border: 1px solid #6C6C6C;
        border-radius: 60px;
        color: #6C6C6C;

        &:hover {
            color: #2D2B2B;
            border: 1px solid transparent;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
        }
    }

    &--outline-red {
        color: #D22329;
        border: 1px solid #D22329;

        &:hover {
            color: #fff;
            box-shadow: none;
            background-color: #D22329;
        }
    }

    &--blue {
        color: #fff;
        background-color: #75BBEE;

        &:hover {
            color: #fff;
            background-color: #569BCD;
        }
    }

    &--outline {
        border: 1px solid #D6D6D6;
        color: #6C6C6C; 
        background-color: transparent;
        
        &:hover {
            color: #2D2B2B;
            border: 1px solid #6C6C6C;
        }
    }

    &--red {
        background-color: #d22329;
        letter-spacing: .1em;
        padding: 12px 28px;
        border: 0;

        &:hover {
            background-color: #F42027;
            color: #fff;
            box-shadow: none;
        }
    }
}