.menu-level {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &__control {
        display: none;
    }

    a {
        display: inline-block;
        border: 1px solid #d22329;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 14px;
        text-align: center;
        color: #d22329;
        font-family: Roboto,sans-serif;
        transition: color .3s,background-color .3s ease;
        margin-right: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        padding: 6px 14px;

       
       &.is-active {
            color: #fff;
            background-color: #D22329;    
       }

       &:hover {
            color: #fff;
            background-color: #D22329;   
       }
    }
}

@media (max-width:1200px) {

    .banner {
        display: none;
    }

    .menu-level {
    
        position: absolute;
        width: 100%;
        z-index: 5;
        transition: box-shadow 0.3s ease;
        
        a {
            
        }

        &__control {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            z-index: 15;
            padding-bottom: 4px;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;
        }

        &.is-open {
            box-shadow: 0px 14px 32px rgba(0, 0, 0, 0.38);
            z-index: 45;

            .menu-level {
                &__control {
                    transform: rotate(180deg);
                }
            }

            a {
                opacity: 1;
                pointer-events: auto;
                
                &.is-active {
                    border-color: transparent;
                    pointer-events: auto;
                }


                &::before {
                    opacity: 1;
                }
                
            }
        }

        a {
            position: relative;
            min-height: 45px;
            width: 100%;
            border-radius: 0;
            text-align: left;
            margin: 0;
            padding: 2px 14px;
            display: flex;
            align-items: center; 
            z-index: 15;
            font-weight: 600; 
            order: 2;
            opacity: 0;
            pointer-events: none;
            background-color: #fff;
            transition: opacity 0.3s ease;
            border: 0;
            color: #2D2B2B;
            pointer-events: none;

            &::before {
                content: " ";
                position: absolute;
                width: calc(~"100% - 2px");
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: #f6f6f6;
                display: block;
                height: 1px;
                bottom: 0;
                opacity: 0;
                transition: opacity 0.3s ease;
            }


            &.is-active {
                order: 1;
                color: #d22329;
                opacity: 1;
                background-color: #fff;
                border: 1px solid rgba(210, 35, 41, 0.5);

                &::before {
                    background-color: #D22329;
                }
            }
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-wrap: wrap;
            position: relative;
        }
  

        &__block {
            height: 45px;
            margin-bottom: 30px;
        }

        &__block {
            display: flex;
        }
    }
}

@media (max-width:560px) {
    .menu-level {

        &__control {
            width: 40px;
            height: 40px;
        }

        a {
            min-height: 40px;
        }

        &__block {
            height: 40px;
        }
        
    }
}