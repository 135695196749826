.news {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;

    &__photo {
        width: 149px;
        height: 145px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        flex: none;
        margin-right: 18px;
        background-position: center;
        background-size: cover;
        
    }

    &__item {
        display: flex;
        align-items: flex-start;
        width: calc(~"50% - 30px");
        margin-bottom: 30px;
        padding:  0 15px;
    }

    &__date {
        line-height: 15px;
        font-size: 12px;
        color: #6c6c6c;
        margin-bottom: 6px;
    }

    &__text {
        font-family: Roboto,sans-serif;
        line-height: 20px;
        font-size: 14px;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        color: #2d2b2b;
        text-decoration: none;
        line-height: 1.2;
        display: inline-block;
        margin-bottom: 7px;
        transition: color 0.3s ease;

        &:hover {
            color: #D22329;
        }
    }

    &__more {
        use {
            fill: #569BCD;
            transition: fill 0.3s ease;
        }

        &:hover {
            use {
                fill: #D22329;
            }
        }
    }
}

.news-detail {
    img {
        max-width: 264px;
        float: left;
        margin: 2px 29px 7px 0;
        border: 1px solid rgba(0,0,0,.1);
    }
}

@media (max-width: 992px) {
    .news {
        &__item {
            width: 100%;
        }
    }
}

@media (max-width: 560px) {
    .news {
        &__photo {
            display: none;
        }
    }

    .news-detail {
        img {
            float: none;
        }
    }
}

