.radio {
    display: inline-flex;
    position: relative;

    &__row {
        position: relative;
        display: flex;
        margin-bottom: 9px; 

        input[type="radio"] {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            &:checked {
                &+.radio {
                    &__control {
                        background-color: #fff;
                        border: 4px solid #75BBEE;
                    }
                }
            }
    
        }
    }



    &__control {
        display: flex;
        position: relative;
        flex: none;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #D6D6D6;
        transition: background-color 0.3s, border-color 0.3s, border-width 0.3s ease;
        margin-right: 8px;
    }

    &__text {
        line-height: 17px;
        font-size: 13px;
        font-family: Roboto,sans-serif;
        color: #6C6C6C;
    }
}