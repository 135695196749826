.breadcrumbs {
    background-color: #F6F6F6;

    ul {
        display: flex;
        min-height: 50px;
        margin: 0;
        padding: 0;
        align-items: center;
        flex-wrap: wrap;

        li {
            list-style: none;

            svg {
                margin-right: 5px;
                vertical-align: -1px;
            }

            a {
                font-size: 12px;
                color: #6C6C6C;
                text-decoration: none;
                margin-right: 5px;

                .svg-icon {
                    vertical-align: -1px;
                    margin-left: 4px; 
                }
            }

            span {
                font-size: 12px;
                color: #6C6C6C;
            }
        }
    }
}

@media (max-width:992px) {
    .breadcrumbs {
        ul {
            min-height: 40px;
            padding: 8px 0;
        }
    }
}