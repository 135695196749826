.header {

    display: flex;
    flex-direction: column;
    position: relative;

    &__catalog {
        display: none;
    }
    
    &__main {
        display: flex;
        height: 70px;
        padding: 0 16px;
        background-image: url(../images/common/bg.jpg);
        align-items: center;
        justify-content: space-between;
        background-size: cover;
    }

    &__search {
        display: flex;
    }

    &__nav {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

@media (max-width: 992px) {
    .header {
        &__search {
            display: none;
        }

        &__catalog {
            display: flex;
            align-items: center;
            font-weight: 600;
            line-height: normal;
            margin-left: 38px;
            font-size: 12px;
            text-transform: uppercase;
            color: #fff;
            height: 100%;

            .svg-icon {
                transition: transform 0.3s, margin 0.3s ease;
            }

            &.is-open {
                .svg-icon {
                    transform: rotate(180deg);
                    margin-top: 5px;
                }
            }

            span {
                margin-right: 10px;
            }

            .svg-icon {
                margin-top: -4px;
            }
            
        }
    }
}

.logo-wrap {
    position: relative;
    width: 50px;
    height: 50px;

}

a.logo-wrap  {

    &:hover {
        .logo-circle {
            transform: rotateY(-180deg);
        }
    }
}

.logo-circle {
    transition: transform 0.5s ease;
}



.logo-text {
    position: absolute;
    top: 16px;
    left: 8px;
}

@media (max-width: 480px) {
  
    .header {
        &__catalog { 
            margin-left: 25px;
        }
    }
}

@media (max-width: 360px) {
    .header {
        &__catalog { 
            margin-left: 25px;
            
            span {
                span {
                    display: none;
                }
            }
        }
    }
}

.gamburger {
    display: none;
}

@media (max-width: 992px) {

    .gamburger {
        display: block;

        img {
            vertical-align: bottom;
        }
    }
}
