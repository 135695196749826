.slider-range {

    &__slide {
        margin-bottom: 19px;
    }

    &__title {
        margin-bottom: 21px;

        span {
            font-size: 14px;
            color: #6c6c6c;
            font-family: Roboto,serif;
            margin-right: 2px;
        }

        input[type="text"] {
            width: 54px;
            height: 27px;
            outline: 0;
            background: #FFFFFF;
            border: 1px solid #D6D6D6;
            font-size: 14px;
            text-align: center;
            font-family: "Roboto", serif;
            color: #2D2B2B;
            margin-right: 5px;
        }
    }
    .ui-widget.ui-widget-content {
        height: 4px;
        border: 0;
        background-color: #D6D6D6;
        margin-bottom: 19px;
    }

    .ui-slider-horizontal .ui-slider-handle {
        margin-left: -7px;
    }

    .ui-slider-horizontal .ui-slider-range {
        background-color: #75BBEE;
    }

    .ui-state-default, .ui-widget-content .ui-state-default {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid #75BBEE;
        outline: 0;
        background-color: #fff;
    }
}