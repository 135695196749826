.news-small {

    &__item {
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 11px;
        margin-bottom: 13px;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__title {
        line-height: 25px;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__date {
        line-height: 14px;
        font-size: 11px;
        color: #6C6C6C;
    }

    &__name {
        line-height: 17px;
        color: #2d2b2b;
        text-decoration: none;
        font-family: Roboto,sans-serif;
        font-size: 13px;
        display: inline-block;
        margin-top: 4px;
    }
}