.license {


    &__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        
    }

    &__item {
        display: block;
        text-decoration: none;
        padding: 0 10px;
        width: 25%;
        margin-bottom: 20px;

        img {
            float: none;
            margin: 0;
            max-width: 100%;
            width: 100%;
        }
    }
}

@media (max-width: 560px) {
    .license {
        &__item {
            width: 33.3%;
        }
    }
}

@media (max-width: 560px) {
    .license {
        &__item {
            width: 50%;
        }
    }
}

@media (max-width: 340px) {
    .license {
        &__item {
            width: 100.3%;
        }
    }
}