.form {

    &__check {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;

        input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 5;
            width: 15px;
            height: 15px;

            &:checked {
                &+.form {
                    &__check {
                        &-control {

                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &-control {
            width: 15px;
            height: 15px;
            background-color: #FFFFFF;
            border-radius: 2px;
            margin-right: 7px;
            position: relative;

            &::before {
                content: " ";
                background-image: url(../images/common/checkw.svg);
                display: block;
                position: absolute;
                opacity: 0;
                transition: opacity .3s ease;
                width: 14px;
                height: 10px;
                top: 1px;
                left: 0;
            
            }
        }

        &-text {
            line-height: 17px;
            font-size: 12px;
            color: #6C6C6C;
            font-family: 'Roboto', sans-serif;

            a {
                text-decoration: underline;
                line-height: 17px;
                font-size: 12px;
                color: #6C6C6C; 
                transition: color 0.3s ease; 

                &:hover {
                    color: #D22329;
                }
            }
        }
    }

    &__btn {
        .btn {
            width: 100%;
            letter-spacing: 0.1em;
            padding: 15px 28px;
        }
    }

    &__field {
        position: relative;
        margin-bottom: 20px;

        label {
            position: absolute;
            top: 0;
            height: 100%;
            padding: 12px 18px;
            line-height: 23px;
            font-size: 16px;
            color: #6c6c6c;
            width: 100%;
            transition: opacity 0.3s ease;

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
            }
        }

        input[type="text"] {
            padding: 0 18px;
            line-height: 23px;
            font-size: 16px;
            color: #6c6c6c;
            height: 45px;
            background-color: #fff;
            outline: 0;
            width: 100%;
            border-color: transparent;

            &::-webkit-input-placeholder { 
                font-size: 16px;
                color: #6c6c6c;
                font-family: Montserrat,sans-serif;
            }
            &::-moz-placeholder { 
                font-size: 16px;
                color: #6c6c6c;
                font-family: Montserrat,sans-serif;
            }
            &:-moz-placeholder  { 
                font-size: 16px;
                color: #6c6c6c;
                font-family: Montserrat,sans-serif;
            }
            &:-ms-input-placeholder { 
                font-size: 16px;
                color: #6c6c6c;
                font-family: Montserrat,sans-serif;
            }
        }

        textarea {
            background-color: #FFFFFF;
            padding: 18px;
            line-height: 23px;
            font-size: 16px;
            color: #6c6c6c;
            background-color: #fff;
            outline: 0;
            width: 100%;
            border-color: transparent;
            height: 135px;
        }
    }
}