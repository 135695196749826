.popup {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    width: 460px;
    position: relative;

    &--ok {
        .popup {
            &__top {
                &-close {
                    top: 50%;
                }
            }

            &__body {
                min-height: 132px;

                span {
                    font-size: 18px;
                    color: #569BCD;
                    margin-left: 11px;
                }
            }

            &__top {
                display: none;

                &-close {
                    fill: #6C6C6C;
                }
            }
        }
    }


    &__row {
        &-ok {
            display: flex;
        }
    }
    
    .form {
        &__check {
            &-control {
                &::before {
                    background-image: url(../images/common/checkred.svg) !important;
                    top: 2px;
                    left: 0px;
                }
            }
        }
    }

    &__body {
        background-color: #eaeaea;
        padding: 42px 50px;
    }

    &__top {
        padding: 0 50px;
        display: flex;
        align-items: center;
        background-image: url(../images/common/popup.jpg);
        height: 100px;
        position: relative;
        flex: none;
        background-position: left;

        &::before {
            content: " ";
            border: 8px solid transparent;
            border-left: 8px solid #8b0041;
            border-top: 8px solid #8b0041;
            display: block;
            position: absolute;
            left: 0;
            bottom: -16px;
        }

        &-title {
            font-weight: 700;
            font-size: 20px;
            color: #fff;
            padding-right: 20px;
        }

        &-close {
            height: 16px;
            position: absolute;
            cursor: pointer;
            right: 50px;
            top: 50px;
            transform: translateY(-50%);
            transition: opacity 0.3s ease;
            z-index: 10;
            fill: #fff;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    &__modal {
        position: fixed;
        display: flex;
        height: 100%;
        width: 100%;
        padding: 15px;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 65;
        background: rgba(8, 24, 65, 0.41);
        backdrop-filter: blur(10px);
        pointer-events: none;
        opacity: 0;
        overflow-y: auto;
        transition: opacity 0.3s ease;

        &.is-open {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

@media (max-width: 560px) {
    .popup {
        &__body {
            padding: 30px 20px;
        }

        &__body {
            flex-grow: 1;
            overflow-y: auto;
        }

        &__modal {
            padding: 10px;
        }

        &__top {
            padding: 0 20px;

            &-close {
                right: 20px;
            }
        }
    }
}