.card-detail {
    display: flex;
    align-items: center;
    margin-top: -23px;
    min-height: 680px;
    position: relative;

    &__more {
        display: none;
    }

    &__head {
        display: none;
    }

    .row {
        display: flex;
        align-items: center;
        padding-bottom: 45px;
    }

    .threesixty {
        width: calc(~"100% - 50px") !important;
        min-height: 500px;
        height: auto!important;
        margin: 0;

        .nav_bar {
            right: 0;
            width: 100%;
        }
    
        .nav_bar a.nav_bar_previous {
            background-image: url(../images/common/rotate-left.png)!important;
            width: 70px!important;
            height: 69px!important;
            background-position: 0 0!important;
            position: absolute;
            left: 0;
            top: 210px;
            transition: opacity 0.3s ease;
    
            &:hover {
                opacity: 0.5;
            }
        }
    
        .nav_bar a.nav_bar_next {
            background-image: url(../images/common/rotate-right.png)!important;
            width: 70px!important;
            height: 69px!important;
            background-position: 0 0!important;
            position: absolute;
            right: 0;
            top: 210px;
    
            transition: opacity 0.3s ease;
    
            &:hover {
                opacity: 0.5;
            }
        }
    
        li {
            &::before {
                display: none;
            }
        }
    
        li {
            width: 100%;
            min-height: 500px;
            position: absolute!important;
            padding-left: 0 !important;
    
            img {
                height: 500px !important;
            }
        }
    }

    &__slider {
        display: flex;
        align-items: center;
        padding-bottom: 50px;
        position: relative;
        z-index: 10;
        
        .slick-slide {
            outline: 0;
        }
    }

    &__for {
        float: left;
        height: 467px;
        width: 467px;

        &-item {
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &__nav {
        float: left;
        width: 51px;
        margin-right: 20px;

        .slick-prev {
            width: 51px;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 40;
            cursor: pointer;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.8;
            }
        }

        .slick-next {
            width: 51px;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.3s ease;
            transform: rotate(180deg);
            z-index: 40;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }


        .slick-current {
            img {
                opacity: 1;
            }
        }

        &-item {
            width: 50px;
            height: 50px;
            margin: 5px 0; 
            border: 1px solid rgba(0, 0, 0, 0.1);

            &__img {
                img {
                    opacity: 1;
                }
            }

            img {
                opacity: 0.7;
                width: 100%;
                height: 100%;
                transition: opacity 0.3s ease;
            }
        }
         
    }

    &::before {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 273px;
        background: linear-gradient(1turn,rgba(0,0,0,.12),transparent);
        opacity: .5;
        display: block;
        width: 100%;
        z-index: 10;
    }

    &__tab {

        
        &-tab  {
            display: none;
            padding: 44px 0;
            -webkit-animation: fadeEffect 1s;
            animation: fadeEffect 1s;
            font-size: 14px;

            a {
                color: #d22329;
                text-decoration: none;
            }

            .card-detail__tech {
                &-row {
                    margin-bottom: 8px;

                    div {
                        span {
                            font-size: 14px; 
                        }
                    }
                }
            }
            
            &.is-active {
                display: block;
            }
        }
    

        &-block {
            margin-top: -44px;
            position: relative;
            z-index: 30;
        }

        &-control {
            display: flex;
            justify-content: center;

            &-item {
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: #2d2b2b;
                transition: background-color .3s ease;
                padding: 10px 24px;
                cursor: pointer;

                span {
                    border-bottom: 1px dotted transparent;
                    transition: border-color 0.3s ease;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.3);
                    span {
                        border-color: #6C6C6C;
                    }
                }
                
                &.is-active {
                    background-color: #fff;
                }
            }
        }
    }

    &__info {
  
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: -30px;
        position: relative;
        z-index: 25;
        padding-bottom: 50px;

        .btn {
            align-self: flex-start;
        }
    }

    &__tech {
        margin-top: 20px;
        margin-bottom: 34px;

        &-title {
            font-weight: 600;
            line-height: normal;
            font-size: 14px;
            color: #2D2B2B;
            margin-bottom: 15px;
        }

        &-row {
            display: flex;
            line-height: 1;
            margin-bottom: 13px;

            div {
                width: 50%;

                &:last-child {
                    span {
                        color: #2D2B2B;
                        
                    } 
                }

                &:first-child {
                    position: relative;

                    &::before {
                        content: " ";
                        width: 100%;
                        border-bottom: 1px dotted #DCDCDC;
                        position: absolute;
                        bottom: -1px;
                        left: 0
                    }

                    span {
                        position: relative;
                        padding-right: 5px;

                        &::before {
                            content: " ";
                            width: 100%;
                            background-color: #fff;
                            height: 2px;
                            position: absolute;
                            bottom: -1px;
                            left: 0
                        }
                    }
                }
                

                span {
                    background-color: #fff;
                    font-family: Roboto,sans-serif;
                    line-height: 16px;
                    font-size: 16px;
                    color: #6c6c6c;
                    display: inline-block;
                }
            }
        }
    }
}



@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}

.card-delivery {
    background-color: #f6f6f6;
    padding-top: 42px;
    padding-bottom: 60px;

    &__item {
        display: flex;
    }

    &__body {
        padding-left: 12px;
        padding-top: 10px;
    }

    &__text {
        font-family: "Roboto",sans-serif;
        line-height: 20px;
        font-size: 14px;
    }

    &__name {
        font-weight: 700;
        line-height: 25px;
        font-size: 18px;
        color: #2d2b2b;
        margin-bottom: 8px;
    }
}


@media (max-width: 1200px) {
    .card-detail {
        min-height: 600px;

        h1 {
            line-height: 40px;
                 font-size: 26px;
        }
        

        &__for {
            height: 400px;
            width: 400px;
        }


        &__tab {
            &-tab {
                padding: 44px 0;
                
                padding-bottom: 0;
            }
        }
    }
}

@media (max-width: 992px) {
    .card-detail {
        margin-top: 0;
        min-height: 668px;
        margin-top: -15px;

       .threesixty {
        width: 400px!important;
        height: 400px!important;
        min-height: 400px;

            li {
                min-height: 400px;

                img {
                    height: 400px !important;
                    left: 0;
                }
            }
       }

        .row {
            padding-bottom: 20px;
        }

        .col-lg-7 {
            text-align: center;
        }

        &__slider {
            padding-bottom: 0;
            margin-bottom: 37px;
        }

        h1 {
            display: none;
            margin-bottom: 39px;
            margin-top: -35px;
        }

        &__more {
            display: inline-flex;
        }

        &__info {
            display: none;
        }

        &__head {
            display: block!important;
            margin-top: -12px;
            text-align: center;
        }

        .row {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

@media (max-width: 768px) {

    .card-delivery {
        padding-top: 30px;
        padding-bottom: 20px;

        &__item {
            margin-bottom: 20px;
        }
    }

    .card-detail {

        &__nav {
            display: none !important;
        }

        &__slider {
            .slick-prev {
                display: inline-block;
                position: absolute;
                left: -37px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                padding: 0;
                background-color: transparent;
                border: 0;
                width: 37px;

                img {
                    max-width: 100%;
                }
            }

            .slick-next {
                display: inline-block;
                position: absolute;
                right: -37px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                padding: 0;
                background-color: transparent;
                border: 0;
                width: 37px;

                img {
                    max-width: 100%;
                }
            }
        }


        &__tab {

            &-block {
                margin-top: -41px;
            }

            &-control {
                &-item {
                    padding: 10px 18px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .card-detail {
        padding-bottom: 90px;
        margin-top: 15px;
        min-height: calc(~"100vw + 160px");
        

        &__slider {
            .slick-prev {
                left: -30px;
            }
            
            .slick-next {
                right: -30px;
            }
        }

        h1 {
            margin-top: 0;
            margin-bottom: 15px;
        }


        &__for {
            height: calc(~"100vw - 88px");
            width: calc(~"100vw - 88px");
            margin: 0 auto;
        }

        &__tab {
            &-block {
                margin-top: -82px;
            }
            margin-top: -82px;
            &-control {
                flex-wrap: wrap;

                &-item {
                    width: 50%;
                }
            }
        }
    }
}

@media (max-width: 560px) {
    .card-detail {
        .threesixty {
            width: calc(~"100vw - 30px")!important;
            height: calc(~"100vw - 30px")!important;
            min-height: calc(~"100vw - 30px")!important;

            .nav_bar a.nav_bar_previous {
                top: calc(100vw/2);
                transform: translateY(-100%);
                width: 45px!important;
                height: 50px!important;
                            
                background-size: contain;
            }

            .nav_bar a.nav_bar_next {
                top: calc(100vw/2);
                transform: translateY(-100%);
                width: 45px!important;
                height: 50px!important;
                
    background-size: contain;
            }

            

            li {
                min-height: calc(~"100vw - 30px")!important;

                img {
                     height: calc(~"100vw - 30px")!important;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .card-detail {
        h1 {
            line-height: 29px;
            font-size: 22px;
        }
    }
}