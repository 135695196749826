.main-banner {
    display: flex;
    position: relative;
    height: 668px;
    margin-top: -23px;
    overflow: hidden;

    &__marker {
        position: absolute;
        cursor: pointer;

        &:hover {
            .main-banner {
                &__marker {
                    &-icon {
                       position: absolute;
                       bottom: 0;
                       left: 0;
                        opacity: 0;
                    }
    
                    &-text {
                        position: relative;
                        opacity: 1;
                        z-index: 10;
                        pointer-events: auto;
                    }
                }

            }
        }

        &--1 {
            bottom: 62%;
            left: 40%;
        }

        &--2 {
            left: 66%;
            bottom: 65%;
        }

        &--3 {
            left: 40%;
            bottom: 44%;
        }

        &--4 {
            left: 63.5%;
            bottom: 44%;
        }

        &--5 {
            left: 78.5%;
            bottom: 33%;
        }

        
        &--6 {
            left: 82.5%;
            bottom: 17%;
        }

        &--7 {
            left: 62.5%;
            bottom: 27%;
        }

        &-text {
            position: absolute;
            width: 211px;
            bottom: 0;
            background: rgba(171,17,23,.83);
            border-radius: 10px 10px 10px 0;
            padding: 12px;
            padding-left: 25px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            span {
                display: block;
                font-family: Roboto,sans-serif;
                font-size: 13px;
                color: #fff;
                position: relative;
                display: inline-block;
                line-height: 1.3;
                margin-bottom: 7px;

                &::before {
                    content: " ";
                    height: 2px;
                    width: 6px;
                    background-color: #fff;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -13px;
                }
            }

            a {
                display: block;
                font-family: Roboto,sans-serif;
                font-size: 13px;
                color: #fff;
                position: relative;
                display: inline-block;
                line-height: 1.3;
                margin-bottom: 7px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                &::before {
                    content: " ";
                    height: 2px;
                    width: 6px;
                    background-color: #fff;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -13px;
                }
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            box-shadow: 0 0 30px hsla(0,0%,100%,.4);
            background-color: #d22329;
            border-radius: 20px 20px 20px 0;
            transition: opacity 0.3s ease;
        }
    }

    .btn {
        align-self: flex-start;
    }

    &__info {
        position: relative;
        z-index: 4;
        height: 668px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__img {
        height: 668px;
        display: flex;
        align-items: center;
        width: calc(~"100% + 235px");
        margin-left: -103px;

        img {
            max-width: 100%;
        }
    }
    
    &__logo {
        margin-bottom: 12px;
    }

    &__title {
        font-weight: 700;
        font-size: 32px;
        color: #2d2b2b;
        line-height: 1.2;
        margin-bottom: 25px;
    }
}

@media (max-width: 1200px) {    
    .main-banner {
       
        flex-wrap: wrap;
        height: auto;

        &__title {
            font-size: 18px;
        }

        &__logo {
            img {
                max-width: 100%;
            }
        }

        &__img {
            height: 600px;
        }

        &__info {
            height: 600px;
        }

        &__info {
            padding-top: 54px;
            width: 100%;
            align-items: center;

            .btn {
                align-self: center;
            }

            br {
                display: none;
            }
        }
    }
}

@media (max-width: 992px) {
    .main-banner {
        &__info {
            height: auto;
        }

        &__title {
            text-align: center;
        }

        &__img {
            height: auto;
        }

        &__marker {

            &:hover {
                .main-banner{
                    &__marker {
                        &-icon {
                            left: inherit;
                            right: 0;
                        }
                    }
                }
            }

            &--1 {
                bottom: 63%;
                left: 38%;
            }

            &--2 {
                left: 64%;
                bottom: 67%;
            }

            &--3 {
                left: 38%;
                bottom: 45%;
            }

            &--4 {
                left: 62%;
                bottom: 44%;
            }

            &--5 {
                left: inherit;
                bottom: 31%;
                right: 19%;

                .main-banner {
                    &__marker {
                        &-text {
                            border-radius: 10px 10px 0px 10px;
                            right: 0;
                        }
                    }
                }
            }

            &--6 {
                left: inherit;
                bottom: 13%;
                right: 14%;

                .main-banner {
                    &__marker {
                        &-text {
                            border-radius: 10px 10px 0px 10px;
                            right: 0;
                        }
                    }
                }
            }

            &--7 {
                left: 60.5%;
                bottom: 24%;
            }
        }

        &__img {
            width: calc(~"100% + 260px");
            margin-left: -130px;
        }
    }
}

@media(max-width: 560px) {
    .main-banner {
        &__img {
            margin: 20px -70px;
            width: calc(~"100% + 140px");
        }

       /* .main-banner {
            &__marker {
                &-text {
                    border-radius: 10px 10px 0px 10px;
                    right: 0;
                }
            }
        }*/

        &__marker {
           /* &--1 {
                top: 28%;
                right: 55%; 
            }*/

            &--2 {
                right: 31%;
                left: inherit;

                .main-banner {
                    &__marker {
                        &-text {
                            border-radius: 10px 10px 0px 10px;
                            right: 0;
                        }
                    }
                }
            }
/*
            &--3 {
                right: 56%;
                top: 49%;
            }*/

            &--4 {
                right: 33%;
                left: inherit;

                .main-banner {
                    &__marker {
                        &-text {
                            border-radius: 10px 10px 0px 10px;
                            right: 0;
                        }
                    }
                }
            }
/*
            &--5 {
                right: 17%;
                top: 60%;
            }

            &--6 {
                top: 79%;
            }*/

            &--7 {
                right: 34%;
                left: inherit;

                .main-banner {
                    &__marker {
                        &-text {
                            border-radius: 10px 10px 0px 10px;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

}